import _ from 'underscore';
import Cookies from 'js-cookie';
import { parse } from 'query-string';
import nextStartDate from '@utils/nextStartDate';
import { safeCredentials, handleErrors } from '@utils/fetchHelpers';
import conversionTracker from '@utils/conversionTracker';

var checkBrowserCompatibility = function () {
  const ua = window.navigator.userAgent;
  var oldBrowser = ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;
  if (oldBrowser === true) {
    window.location = '/upgrade';
  }
}

checkBrowserCompatibility();

// var injectDataToWebsite = function () {
//   var nextStartDateObj = new nextStartDate();
//   var standard = nextStartDateObj.standard()
//   var monthYear = nextStartDateObj.monthYear()
//   var shortMonthYear = nextStartDateObj.shortMonthYear()
//   var cohortNumber = nextStartDateObj.cohortNumber()

//   Array.prototype.forEach.call(document.getElementsByClassName("next_start_date-short-month-year"), function (el) {
//     el.innerHTML = shortMonthYear;
//   });

//   Array.prototype.forEach.call(document.getElementsByClassName("next_start_date-month-year"), function (el) {
//     el.innerHTML = monthYear;
//   });

//   Array.prototype.forEach.call(document.getElementsByClassName("next_start_date-standard"), function (el) {
//     el.innerHTML = standard;
//   });

//   Array.prototype.forEach.call(document.getElementsByClassName("next_start_date-cohort-number"), function (el) {
//     el.innerHTML = cohortNumber;
//   });
// }

var addFooterNewsletterCallback = function () {
  window.footerNewsletterSignupSubmit = function (token, action) {

    var form = document.getElementById('footer-email-sub');

    var first_name = form.querySelector('input[name="first_name"]').value;
    var email = form.querySelector('input[name="email"]').value;

    if (!first_name || !email) {
      alert('please provide name and email');
      return;
    }

    form.querySelector('button').disabled = true;

    fetch('/api/v2/email_subscriptions/add_tag', safeCredentials({
      method: 'POST',
      body: JSON.stringify({
        first_name: first_name,
        email: email,
        tag_name: 'monthly_update',
        token: token,
        recaptcha_action: action,
      }),
    }))
    .then(res => res.json())
    .then(res => {
      if (res.success) {
        conversionTracker(window, 'Lead', {
          content_category: 'email_susbcriptions',
          content_name: 'monthly_update',
          first_name: first_name,
          email: email,
        });

        form.classList.toggle('d-none');
        document.querySelector('#footer .newsletter .success-message').classList.toggle('d-none');
      } else if (res.message) {
        form.querySelector('button').disabled = false;
        window.alert(res.message);
      }
    });
  };
}

var grabConversionTrackerInstructions = function () {
  Array.prototype.forEach.call(document.getElementsByClassName('conversion_tracker'), function (el) {
    conversionTracker(window, el.dataset.event, JSON.parse(el.dataset.options));
  })
}

const checkAuthAndSetFFK = function () {
  fetch('/api/v2/authenticated_user')
    .then(handleErrors)
    .then(user => {
      if (user.currentUser && window.ffk) {
        window.ffk('people', 'set', {email: user.currentUser.email});
      }
    })
    .catch(() => {
      console.error('not logged in')
    })
}

const checkCookieAndRefferal = function () {
  const query = parse(window.location.search);

  if (Object.keys(query).length > 0) {
    const whitelistKeys = Object.keys(query).filter(key => key.includes('utm_') || key.includes('fbclid') || key.includes('ffk_') || key.includes('ref'));
    if (whitelistKeys.length > 0) {
      const newReferral = _.pick(query, whitelistKeys);
      newReferral.time = new Date().toUTCString();

      if (window && window.ffk) {
        newReferral.landing_page = window.location.href;

        if (document && document.referrer) {
          newReferral.doucment_referrer = document.referrer;
        }

        if (newReferral.ffk_person_id) {
          window.ffk('people', 'set', {email: newReferral.ffk_person_id});
        }
        window.ffk('track', 'Referral', newReferral);
      }

      if (Cookies.get("referral_history")) {
        const history = Cookies.getJSON("referral_history");
        history.push(newReferral);
        Cookies.set("referral_history", history, { expires: 7300 });
      } else {
        Cookies.set("referral_history", [newReferral], { expires: 7300 });
      }
    }
    if (query.discount_code && query.discount_code === 'videoask10') {
      Cookies.set("discount_code", 'videoask10', { expires: 30 });
    }
    if (query.discount_code && query.discount_code === 'pro10') {
      Cookies.set("discount_code", 'pro10', { expires: 30 });
    }
    if (query.discount_code && query.discount_code === 'cyber15') {
      Cookies.set("discount_code", 'cyber15', { expires: 30 });
    }
  } else if (document.referrer && !document.referrer.includes('https://www.altcademy.com/')) {
    const newReferral = {
      time: new Date().toUTCString(),
    };

    if (window && window.ffk) {
      newReferral.landing_page = window.location.href;

      if (document && document.referrer) {
        newReferral.doucment_referrer = document.referrer;
      }

      window.ffk('track', 'Referral', newReferral);
    }

    if (Cookies.get("referral_history")) {
      const history = Cookies.getJSON("referral_history");
      history.push(newReferral);
      Cookies.set("referral_history", history, { expires: 7300 });
    } else {
      Cookies.set("referral_history", [newReferral], { expires: 7300 });
    }
  }
}


document.addEventListener('DOMContentLoaded', () => {
  checkAuthAndSetFFK();
  checkCookieAndRefferal();
  grabConversionTrackerInstructions();
  addFooterNewsletterCallback();
  // injectDataToWebsite();
});
